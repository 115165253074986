.section3-logos {
  padding-bottom: $pad-large;
  .title-h2 {
    margin-bottom: 50px;
    text-align: center;
  }
  .logos-wrapper {
    display: flex;
    align-items: center;
    gap: 30px;
    @include break(tablet) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
