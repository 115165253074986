.speech {
    position: absolute;
    top: 50%;
    right: 0;
    padding: 60px 100px 35px 65px;
    height: 100%;
    max-height: 80%;
    width: 100%;
    color: $primary;
    border: 3px solid $secondary;
    background-color: $white;
    border-radius: $rounded-lg;
    transform: translateY(-50%);
    
    @include break(tablet) {
        padding: 24px 28px 15px 22px;
        max-width: none;
    }

    .name {
        position: absolute;
        top: -40px;
        left: 40px;
        padding: 12px 35px;
        @include font-medium;
        color: $white;
        text-align: center;
        font-size: 25px;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        background-color: $secondary;
        border-radius: $rounded-lg;

        @include break(tablet) {
            top: -20px;
            left: 30px;
            font-size: 15px;
            border-radius: $rounded-sm;
            line-height: normal;
            padding: 5px 10px;
            letter-spacing: 1.5px;
        }
    }

    .nbr {
        position: absolute;
        top: -40px;
        left: 365px;
        width: 60px;
        height: 60px;
        display: grid;
        place-items: center;
        @include font-medium;
        color: $white;
        font-size: 22px;
        letter-spacing: 2.2px;
        border-radius: 50%;
        background-color: $primary;

        @include break(tablet) {
            top: -20px;
            left: 200px;
            width: 35px;
            height: 35px;
            font-size: 14px;
            letter-spacing: 1.3px;
        }
    }

    .speech-text {
        position: absolute;
        max-width: calc(100% - 100px);
        height: 130px;
        padding-right: 10px;
        font-size: 25px;
        line-height: 31px;
        overflow: auto;
        opacity: 0;
        visibility: hidden;
        transform: translateY(20px);
        transition: opacity 0.25s ease-out, transform 0.25s ease-out;
        
        @include break(tablet) {
            max-width: 90%;
            height: 80px;
            font-size: 14px;
            line-height: 115%;
            overflow: scroll;
            -webkit-overflow-scrolling: touch;
        }

        &[data-question] {
            @include font-medium;
        }

        &.active {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);

            &.split-text {
                transition-delay: .25s;
            }
        }
    }

    .link-external {
        color: $secondary;
        text-decoration: underline;
    }

    .arrow {
        position: absolute;
        bottom: 15px;
        right: 27px;

        @include break(tablet) {
            display: none;
        }
    }
}