.certificate {
    position: absolute;
    top: 50%;
    right: 50px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(calc(-50% - 20px));
    transition: opacity 0.25s ease-out, transform 0.25s ease-out;

    @include break(tablet) {
        top: calc(50% + 10px);
        right: initial;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 210px;
        z-index: 1;
    }

    &.active {
        visibility: visible;
        opacity: 1;
        transform: translateY(-50%);

        @include break(tablet) {
            transform: translate(-50%, -50%);
        }
    }

    .certificate-wrapper {
        .content {
            padding-top: 35px;
            width: 100%;
            max-width: 85%;
            margin: 0 auto;
            font-size: 12px;
            text-align: center;
            
            @include break(tablet) {
                max-width: 80%;
                padding-top: 0;
                margin-top: 25px;
                font-size: 8px;
            }
        }

        .logo {
            margin-bottom: 15px;

            @include break(tablet) {
                max-width: 110px;
                margin: 0 auto;
            }
        }

        .title,
        .text {
            padding: 0 25px;
            
            @include break(tablet) {
                padding: 0 10px;
            }
        }

        .title {
            @include font-medium;
        }

        .text {
            p:first-child {
                margin-bottom: 1rem;
            }
        }

        @include break(tablet) {
            .bg {
                max-width: 210px;
                height: 100%;
            }

            svg {
                max-width: 100%;
                height: 100%;
            }
        }
    }

    .divider {
        display: block;
        width: 100%;
        height: 1px;
        margin: 20px 0;
        background-color: $white;

        @include break(tablet) {
            margin: 13px 0;
        }
    }
}