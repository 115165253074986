@mixin font-face-global($font-name, $file-name, $font-weight, $font-style) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + ".eot");
    src: url("../fonts/" + $file-name + ".eot?#iefix") format('embedded-opentype'),
    url("../fonts/" + $file-name + ".svg#" + $file-name) format('svg'),
    url("../fonts/" + $file-name + ".ttf") format('truetype'),
    url("../fonts/" + $file-name + ".woff") format('woff'),
    url("../fonts/" + $file-name + ".woff2") format('woff2');
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: swap;
  }
}

@include font-face-global(Avenir Next, AvenirNext-Regular, 400, normal);
@include font-face-global(Avenir Next, AvenirNext-Italic, 400, italic);
@include font-face-global(Avenir Next, AvenirNext-DemiBold, 500, normal);
@include font-face-global(Avenir Next, AvenirNext-DemiBoldItalic, 500, italic);
@include font-face-global(Avenir Next, AvenirNext-Bold, 600, normal);
@include font-face-global(Avenir Next, AvenirNext-BoldItalic, 600, italic);


@mixin font-regular {
  font-family: 'Avenir Next', sans-serif;
  font-weight: 400;
}

@mixin font-medium {
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
}

@mixin font-title {
  font-family: 'Avenir Next', sans-serif;
  font-weight: 600; 
}


@font-face {
  font-family: 'Avenir Next';
  src: url('AvenirNext-Bold.eot'); /* IE9+ Compat Modes */
  src: url('AvenirNext-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('AvenirNext-Bold.woff2') format('woff2'), /* Super modern browsers */
       url('AvenirNext-Bold.woff') format('woff'), /* Modern browsers */
       url('AvenirNext-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('AvenirNext-Bold.svg#FontName') format('svg'); /* Legacy iOS */
       font-weight: 600;
       font-style: normal;
}
