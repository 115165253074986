#play-button {
    position: absolute;
    bottom: 115px;
    right: 30px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;
    padding: 5px 15px;
    border: none;
    border-radius: $rounded-sm;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: $white;
    background-color: $primary;
    cursor: pointer;
    transition: all 0.25s ease-out;

    @include break(tablet) {
        bottom: 70px;
        right: 20px;
    }

    span {
        display: block;
        position: relative;
        z-index: 10;
    }

    .arrow {
        display: flex;
        align-items: center;
        padding-left: 10px;
        max-width: 20px;

        svg {
            max-width: 100%;
        }
    }

    &::before,
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &::before {
        content: '';
        background: $secondary;
        width: 120%;
        height: 0;
        padding-bottom: 120%;
        top: -110%;
        left: -10%;
        border-radius: 50%;
        transform: translate3d(0, 68%, 0) scale3d(0, 0, 0);
    }

    &::after {
        content: '';
        background: $secondary;
        transform: translate3d(0, -100%, 0);
        transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    }

    &:hover {
        span {
            animation: ButtonMoveScaleUpInitial 0.25s forwards, ButtonMoveScaleUpEnd 0.25s forwards 0.25s;
        }

        &::before {
            transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
            transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
        }

        &::after {
            transform: translate3d(0, 0, 0);
            transition-duration: 0.05s;
            transition-delay: 0.4s;
            transition-timing-function: linear;
        }
    }
}

#next-question {
    position: absolute;
    bottom: 115px;
    right: 30px;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 3px 0;
    color: $primary;
    cursor: pointer;
    transition: all 0.25s ease-out;

    @include break(tablet) {
        bottom: 70px;
        right: 20px;
        padding: 0;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: $secondary;
        transition: all .25s ease-out;
    }

    &:hover {
        &::after {
            width: 100%;
        }
    }

    .arrow {
        display: flex;
        align-items: center;
        padding-left: 10px;
        max-width: 20px;
        transition: transform .25s ease-out;

        svg {
            max-width: 100%;
        }
    }
}

#back-button {
    display: none;
    position: absolute;
    bottom: 115px;
    right: calc(30px + 90px);
    border: none;
    background: none;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 3px 0;
    color: $primary;
    cursor: pointer;
    transition: all 0.25s ease-out;

    @include break(tablet) {
        bottom: 70px;
        right: calc(20px + 100px);
        padding: 0;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: $secondary;
        transition: all .25s ease-out;
    }

    &:hover {
        &::after {
            width: 100%;
        }
    }

    &.btn-back-fr {
        right: calc(30px + 115px);
    }

    .arrow {
        display: flex;
        align-items: center;
        padding-left: 10px;
        max-width: 20px;
        transform: rotate(180deg);
        transition: transform .25s ease-out;

        svg {
            max-width: 100%;
        }
    }
}