.answers {
    width: 100%;
    position: absolute;
    top: calc(50% - 15px);
    left: 0;
    transform: translateY(-50%);
    z-index: 1;

    @include break(tablet) {
        top: calc(50% - 50px);
        margin-top: 50px;
    }

    .answers-wrapper {
        max-width: 85%;
        margin: 0 auto;
        display: none;
        cursor: pointer;

        &.active {
            display: block;
        }

        &.disabled {
            cursor: not-allowed;

            .question {
                pointer-events: none;
            }
        }

        &.grid {
            display: none;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 0px 40px;
            grid-template-areas:
                "a c"
                "b .";

            @include break(tablet) {
                margin-top: 10px;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
                grid-template-areas:
                    "a"
                    "b"
                    "c";
            }

            &.active {
                display: grid;
            }

            .a {
                grid-area: a;
            }

            .b {
                grid-area: b;
            }

            .c {
                grid-area: c;
            }
        }
    }

    .question {
        display: flex;
        align-items: center;
        padding: 17px 40px;
        margin-bottom: 15px;
        color: $primary;
        border-radius: $rounded-lg;
        background: $white;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        transition: all 0.25s ease-out;

        &:hover {
            transform: translateY(4px);
            box-shadow: 0px 0 0 0px rgba(0, 0, 0, 0.25);
        }

        @include break(tablet) {
            padding: 10px 25px;
            margin-bottom: 10px;
            border-radius: $rounded-sm;
        }

        &.true {
            box-shadow: 0px 0px 0px 6px #9ACA6A, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            
            @include break(tablet) {
                box-shadow: 0px 0px 0px 3px #9ACA6A, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }

            .letter {
                background-color: #9ACA6A;
            }
        }

        &.false {
            opacity: 0.6;

            .letter {
                background-color: #EA5046;
            }
        }
    }

    .letter {
        width: 60px;
        height: 60px;
        min-width: 60px;
        display: grid;
        place-items: center;
        @include font-medium;
        text-align: center;
        font-size: 27px;
        color: $white;
        border-radius: 50%;
        background-color: $primary;

        @include break(tablet) {
            min-width: 35px;
            max-width: 35px;
            height: 35px;
            font-size: 16px;
        }
    }

    .text {
        margin-left: 25px;
        font-size: 27px;

        @include break(small-screen) {
            line-height: 120%;
        }
        
        @include break(tablet) {
            font-size: 15px;
            margin-left: 15px;
        }
    }
}