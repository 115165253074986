.section1-hero {
  position: relative;

  &_image {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: $header-h;

    @include break(tablet) {
      margin-bottom: $header-h_mobile;
    }

    &-desktop {
      height: 53.875rem;
      max-height: 865px;
      background-position: left;

      @include break(tablet) {
        display: none;
      }
    }

    &-mobile {
      height: 33.3rem;
      background-position: center;

      @include break(desktop-only) {
        display: none;
      }
    }
  }

  .content {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);

    @include break(tablet) {
      width: 90%;
    }

    .profil {
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 433px;
      z-index: 2;

      @include break(tablet) {
        bottom: 0;
        left: 0;
        max-width: 183px;
      }
    }
  }
}