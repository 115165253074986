@keyframes ButtonMoveScaleUpInitial {
    to {
        transform: translate3d(0, -105%, 0) scale3d(1, 2, 1);
        opacity: 0;
    }
}

@keyframes ButtonMoveScaleUpEnd {
    from {
        transform: translate3d(0, 100%, 0) scale3d(1, 2, 1);
        opacity: 0;
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes typewriter {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}