footer {
  background-color: $dark;
  padding: $pad-large 0;

  .container {
    max-width: 80vw;
  }

  .wrapper {
    border-top: 1px solid $white;
    display: flex;
    justify-content: space-between;
    padding: 30px 15px 0 15px;

    @include break(tablet) {
      flex-wrap: wrap;
      justify-content: center;
      border-top: 0;
      padding: 0;
    }
  }

  .text-wrapper {
    font-size: 11px;
    @include break(tablet) {
      padding-bottom: $pad-med_mobile;
    }

    p a {
      text-decoration: underline;
    }

    .copyright {
      padding-bottom: $pad-xsmall;
    }
  }

  .social-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include break(tablet) {
      align-items: center;
    }

    .title-social {
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;

      @include break(tablet) {
        padding-bottom: 15px;
      }
    }

    .social a {
      &:hover {
        opacity: 0.85;
      }
    }
  }
}